/* Here we are going to write some classes that are not in Tailwind */

/* I like to clean code the CSS and JS */

html,body{
  overflow-x: hidden;
  font-family: 'Poppins';
}
.background-nav{
  background-image: url('media/Radius.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.background-welcome{
  background-image: url('media/clip-path-group.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.btn-bckgrnd{
  background-color: #201D89;
}
.logo{
  height: 200px;
  width: 140px;
  object-fit: contain;
}
.logo-itms{
  height: 30px;
  width: 30px;
  object-fit: contain;
}
.logo-nav{
    height: 127px;
    width: 78px;
    object-fit: contain;
}
.back-btn{
  width: 30px;
  height: 18px;
  object-fit: contain;
}
.bckgrnd{
  background-color: #3787F259;
}
.txt-blue-500{
  color:#3787F2;
}
input{
  color:#fff;
}
.link{
  color:#03154D9E;
}
input:focus{
  outline:none;
  border:none;
}
input::placeholder{
  color:#fff;
}
.input-modal::placeholder {
  color: #D0D5DD;
  /* Placeholder text color */
}
.input-modal {
  width: 100%;
  /* Full width of the form container */
  padding: 8px;
  /* Comfortable padding inside the input */
  margin-bottom: 10px;
  /* Space between form fields */
  border: 1px solid #D0D5DD;
  color: #03154D;
  /* Border styling */
  border-radius: 8px;  /* Rounded corners for the input fields */
  color: #03154D;  /* Text color for input */
}

.input-modal:focus {
  /* Focus color */
  outline: #03154D;  
    border: 1px solid #D0D5DD;
      outline: none;
}
.search-bar:focus{
  outline: none;
  border:2px solid #03154D9E;
}
.search-bar::placeholder{
  color:#03154D9E;
}
.profile-txt::placeholder{
  color:#03154D;
}
.profile-txt{
  border:2px solid #03154D;
  color:#03154D;
}
.profile-txt:focus{
  border:2px solid #03154D;
}
.search-bar{
  color:#03154D9E;
}
.search-bar{
  border:2px solid #03154D9E;
}
.color-txt{
  color:#03154D9E;
}
.color-head{
  color:#03154D;
}
.color-red{
  color:#FF3E13;
}
.modal-bckgrnd {
  display: flex;
  align-items: center;
  /* Centers the modal vertically in the viewport */
  justify-content: center;
  /* Centers the modal horizontally in the viewport */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  z-index: 1000;
  /* High z-index to ensure it's above other content */
}
.color-background{
  background-color:#363495;
}
.bg-white{
  background-color: white;
}
.bg-chats{
  background-color: #3671F41A;
}
.background-btn{
  background-color:#3671F414;
}
.modal-txt{
  color:#344054;
}
.text-grey{
  color:#667085;
}
.avatar-img{
  height:60px;
  width:60px;
  object-fit: contain;
}
.border-blue{
  border:2px solid #03154D47;
}
.search-input{
  border:1px solid #D0D5DD;
}
.search-input::placeholder{
  color:#03154D9E;
  font-size: 14px;
  font-weight: 500;
}
.search-input:focus{
  outline: none;
  border:none;
}
.img-back{
  height:22px;
  width:22px;
}
.profile-bckgrnd{
  background-color:#3771C814;
}
.details-bckgrnd{
  background-color: #E8EFFF;
}
.details-paragraph{
  color:#446ECB;
}
.detail-name{
  margin-top:-40px;
}
.bckgrnd-detail{
  background-color:#446ECB;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
}

.pagination a.active {
  background-color: #363495;
  color: white;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}
.modal-content {
  background-color: white;
  /* Adjust based on your design needs */
  max-height: 100vh;
  /* 90% of the viewport height */
  overflow-y: auto;
  /* Enables scrolling */
  padding: 10px;
  border-radius: 8px;
  /* Optional: Rounds the corners */
}
.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.align-items-center {
  align-items: center;
}